import React from 'react';
import { Container, Navbar, NavbarBrand, Nav, NavItem, NavbarToggler, Collapse, NavLink, Button } from 'reactstrap';
import { FaWhatsapp } from 'react-icons/fa';
import logo from '../../assets/images/linkEstagio-logo.png';
import style from './headerForm.module.css?v=1.0';
import { useState, useEffect } from 'react';
// Mobile version of the header
function MobileHeader() {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
<div>
      <Navbar color="faded" light>
      <NavbarBrand href="/" className={style.navbarBrand}>
              <img src={logo} alt="Link Estágio" className={style.logo} />
            </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
          <NavItem>
                <NavLink href="/estudante">Estudante</NavLink>
              </NavItem>
          <NavItem>
              <NavLink href="/empresa">Empresa</NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => window.open('https://wa.me/5585997325336', '_blank')}>
              <FaWhatsapp className={style.whatsappIcon} />
              Fale conosco
            </NavLink>
              <NavLink onClick={() => window.open('https://loginfacilcon.com.br', '_blank')}>
                Intranet
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

function Header() {
  
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    checkScreenWidth();

    // Add event listener to check screen size on resize
    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);


  return (
    <header className={style.header}>
      {isMobile ? <MobileHeader /> : (
        <Navbar color="light" light expand="md" className={style.navbar}>
          <Container className={style.navContainer}>
            <NavbarBrand href="/" className={style.navbarBrand}>
              <img src={logo} alt="Link Estágio" className={style.logo} />
            </NavbarBrand>
            <Nav className={style.nav} navbar>
              <NavItem className={style.navItem}>
                <NavLink href="/estudante" className={style.navLink}>ESTUDANTE</NavLink>
              </NavItem>
              <NavItem className={style.navItem2}>
                <NavLink href="/empresa" className={style.navLink}>EMPRESA</NavLink>
              </NavItem>
              <NavItem className={style.navItem}>
                <Button className={style.contactButton} onClick={() => window.open('https://wa.me/5585997325336', '_blank')}>
                  <FaWhatsapp className={style.whatsappIcon} />
                  Fale conosco
                </Button>
              </NavItem>
              <NavItem className={style.navItem2}>

              <Button className={style.IntranetButton} onClick={() => window.open('https://loginfacilcon.com.br', '_blank')}>
                  Intranet
                </Button>
                </NavItem>

            </Nav>
          </Container>
        </Navbar>
      )}
    </header>
  );
}

export default Header;